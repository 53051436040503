export enum StaticUrls {
    LOGIN = '/login',
    FORBIDDEN = '/forbidden',
    FAIL = '/fail',
    RESET_LINK = '/reset-link',
    SIGNUP = '/signup',
    RESET = '/reset-password',
    PROFILE = '/profile',
    MAIN = '/',
}
