import Promotion from '../types/Promotion';
import {useNavigate} from 'react-router-dom';
import {Box, Chip, Typography} from '@mui/material';
import Link from '@mui/material/Link';
import React from 'react';
import ImageWithFallback from './ImageWithFallback';

interface PromotionCardProps {
    promotion: Promotion;
    shallow?: boolean;
    width?: number;
}

export default function PromotionCard({promotion, shallow, width}: PromotionCardProps) {
    const navigate = useNavigate();
    return (
        <Box sx={{display: 'flex', p: 1}}>
            <ImageWithFallback
                width={width}
                onClick={() => navigate('/promotion/' + promotion.id)}
                image={window.location.origin + '/images/' + promotion.picture} />
            <Box sx={{flexGrow: 1, ml: 1}}>
                <Typography variant="h6">{promotion.name}</Typography>
                <Typography variant="body2">{promotion.description}</Typography>
                {!shallow ? <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                    <Link href={'/venue/' + promotion.venue.id}>{promotion.venue.name}</Link>
                </Typography> : null}
                {!shallow ? <Chip size="small" label={promotion.category} /> : null}
            </Box>
        </Box>
    )
}
