import React from 'react';
import apiService from '../common/apiService';
import Promotion from '../types/Promotion';
import ApiError from '../component/ApiError';
import PromotionCard from '../component/PromotionCard';

export default function MainPage() {
    const response = apiService<Promotion[]>('promo');

    if (response.error) {
        return <ApiError error={response.error}/>;
    }

    if (response.isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {response.result.map((el, i) => <PromotionCard key={i} promotion={el} />)}
        </div>
    );
}
