import React, {ReactNode} from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Navigation from './component/Navigation';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {StaticUrls} from './staticUrls';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import ProfilePage from './pages/ProfilePage';
import SignUpPage from './pages/SignUpPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SendResetLinkPage from './pages/SendResetLinkPage';
import {ContextProvider} from './context/AppContext';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import PromoPage from './pages/PromoPage';
import VenuePage from './pages/VenuePage';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
    },
});

interface WrapperProps {
    children: NonNullable<ReactNode>;
}

function Wrapper({children}: WrapperProps) {
    return (
        <>
            <Navigation/>
            {children}
        </>
    )
}

function App() {

    return (
        <ThemeProvider theme={theme}>
            <ContextProvider>
                <div className="App">
                    <BrowserRouter>
                        <Routes>
                            <Route path={StaticUrls.LOGIN} element={<LoginPage/>}/>
                            <Route path={StaticUrls.SIGNUP} element={<SignUpPage/>}/>
                            <Route path={StaticUrls.RESET_LINK} element={<SendResetLinkPage/>}/>
                            <Route path={StaticUrls.RESET} element={<ResetPasswordPage/>}/>
                            <Route path={StaticUrls.MAIN} element={<Wrapper><MainPage/></Wrapper>}/>
                            <Route path={StaticUrls.PROFILE} element={<Wrapper><ProfilePage/></Wrapper>}/>
                            <Route path="/promotion/:id" element={<Wrapper><PromoPage/></Wrapper>}/>
                            <Route path="/venue/:id" element={<Wrapper><VenuePage/></Wrapper>}/>
                        </Routes>
                    </BrowserRouter>
                </div>
            </ContextProvider>
        </ThemeProvider>
    );
}

export default App;
