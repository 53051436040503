import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {Typography} from '@mui/material';
import {sendBody} from '../common/apiUtil';
import HeaderFooterWrapper from '../component/HeaderFooterWrapper';

export default function SendResetLinkPage() {
    const [username, setUsername] = useState<string>('');

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        sendBody(
            {email: username},
            '/api/user/send-reset-link',
            'POST',
            () => window.location.href = '/'
        );
    };

    return (
        <HeaderFooterWrapper>
            <form onSubmit={submit} style={{ maxWidth: '360px', width: '100%' }}>
                <TextField sx={{marginTop: 2, width: '100%'}} variant="outlined" fullWidth label="Email" value={username} onChange={(e) => setUsername(e.target.value)} />
                <Button sx={{marginTop: 2, width: '100%'}} type="submit"  variant="outlined">Send reset link</Button>
                <Typography
                    fontWeight={600}
                    fontSize={14}
                    textAlign="center"
                    marginY="35px"
                >
                    <Link href="/login">Back to login</Link>
                </Typography>
            </form>
        </HeaderFooterWrapper>
    );
}
