import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import {Typography} from '@mui/material';
import {sendBody} from '../common/apiUtil';
import HeaderFooterWrapper from '../component/HeaderFooterWrapper';

export default function LoginPage() {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [err, serErr] = useState<string | undefined>(undefined);

    // const [redirect] = useQueryString('redir');

    const callback = () => {
        // if (redirect) {
        //     window.location.href = redirect;
        // } else {
            window.location.href = '/'
        // }
    }

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        sendBody(
            {
                username: username,
                password: password
            },
            '/login',
            'POST',
            callback,
            serErr
        );
    };

    return (
        <HeaderFooterWrapper>
            <form
                onSubmit={submit}
                style={{ maxWidth: '360px', width: '100%' }}>
                <Typography fontSize={36} fontWeight={600} lineHeight="44px">
                    Log in
                </Typography>
                <Typography margin="12px 0 32px">
                    Welcome! Please enter your details or sign up.
                </Typography>
                {err ? <Alert severity="error">{err}</Alert> : null}
                <TextField sx={{marginBottom: 2, width: '100%'}} variant="outlined" fullWidth label="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                <TextField sx={{marginBottom: 2, width: '100%'}} variant="outlined" fullWidth label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <Typography
                    fontWeight={600}
                    fontSize={14}
                    textAlign="center"
                    marginY="24px"
                >
                    <Link href="/reset-link">Forgot password</Link>
                </Typography>
                <Button type="submit" sx={{marginTop: 2, width: '100%'}} variant="outlined">Sign in</Button>
                <Typography
                    textAlign="center"
                    fontSize={14}
                    marginY="32px"
                >
                    Don&apos;t have an account?{' '}
                    <Link href="/signup" fontWeight={600}>
                        Sign up
                    </Link>
                </Typography>
            </form>
        </HeaderFooterWrapper>
    );
}
