import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import apiClient from '../common/apiClient';

export const AppContext = createContext<AppContextType>({
    global: {
        username: '',
        roles: [],
        subscriptionActive: false,
        subscribedUntil: '',
        subscription: '',
        error: false,
    },
    initialized: false,
});

export function ContextProvider({ children }: AppContextProviderProps) {
    const [global, setGlobal] = useState<Global | undefined>(undefined);

    useEffect(() => {
        async function loadContext() {
            try {
                const response = await apiClient.get<Global>('/user/current');
                setGlobal(response);
            } catch (e) {
                if (e === 403) {
                    setGlobal({
                        username: '',
                        roles: [],
                        subscriptionActive: false,
                        subscribedUntil: '',
                        subscription: '',
                        error: false,
                    });
                } else {
                    setGlobal({
                        username: '',
                        roles: [],
                        subscriptionActive: false,
                        subscribedUntil: '',
                        subscription: '',
                        error: true,
                    });
                }
            }
        }
        loadContext();
    }, [setGlobal]);

    const defaultContext = useMemo(
        () => ({
            global,
            initialized: typeof global !== 'undefined',
        }),
        [global]
    );

    return <AppContext.Provider value={defaultContext}>{children}</AppContext.Provider>;
}

export function useAppContext(): AppContextType {
    return useContext(AppContext);
}

interface AppContextProviderProps {
    children: ReactNode;
}

interface AppContextType {
    global?: Global;
    initialized: boolean;
}

interface Global {
    username: string;
    roles: string[];
    subscriptionActive: boolean;
    subscribedUntil: string;
    subscription: string;
    error: boolean;
}
