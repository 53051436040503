import {StaticUrls} from '../staticUrls';

export function encodedLocation() {
    const uri = window.location.pathname + window.location.search;
    if (uri.startsWith(StaticUrls.LOGIN)) {
        return ''
    }
    return encodeURIComponent(uri);
}

export function getCookie(name: string) {
    const value = '; ' + document.cookie;
    const parts: string[] = value.split('; ' + name + '=');
    if (parts.length === 2) {
        const pop = parts.pop();
        if (pop) {
            return pop.split(';').shift();
        }
    }
    return null;
}
