import React, {useState} from 'react';
import {sendBody} from '../common/apiUtil';
import {Alert, Button, Checkbox, FormControlLabel, TextField, Link, Typography} from '@mui/material';
import HeaderFooterWrapper from '../component/HeaderFooterWrapper';

export default function SignUpPage() {
    const termsUrl = 'https://google.com';
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [err, serErr] = useState<string | undefined>(undefined);
    const [accept, setAccept] = useState<boolean>(false);
    const [marketingPermission, setMarketingPermission] = useState<boolean>(false);

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        sendBody(
            {
                email: username,
                password: password,
                marketingPermission
            },
            '/api/user/signup',
            'POST',
            () => window.location.href = '/',
            serErr
        );
    };

    return (
        <HeaderFooterWrapper>
            <form onSubmit={submit} style={{ maxWidth: '360px', width: '100%' }}>
                <Typography fontSize={36} fontWeight={600} lineHeight="44px">
                    Sign Up
                </Typography>
                <Typography margin="12px 0 32px">
                    Welcome! Please enter your details.
                </Typography>
                {err ? <Alert severity="error">{err}</Alert> : null}
                <TextField sx={{marginBottom: 2, width: '100%'}} variant="outlined" fullWidth label="Email" value={username} onChange={(e) => setUsername(e.target.value)} />
                <TextField sx={{marginBottom: 2, width: '100%'}} variant="outlined" fullWidth label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <FormControlLabel
                    sx={{ alignItems: 'flex-start' }}
                    control={
                        <Checkbox
                            sx={{
                                paddingTop: 0,
                            }}
                            checked={marketingPermission}
                            onChange={() =>
                                setMarketingPermission(!marketingPermission)
                            }
                            name="marketingPermission"
                        />
                    }
                    label={
                        <Typography fontSize={14} fontWeight={300}>
                            Yes, I would like to receive marketing emails from
                            Quanted with news, offers, and updates
                        </Typography>
                    }
                />
                <FormControlLabel
                    sx={{
                        alignItems: 'flex-start',
                        marginTop: '8px',
                        marginBottom: '35px',
                    }}
                    control={
                        <Checkbox
                            sx={{
                                paddingTop: 0,
                            }}
                            checked={accept}
                            onChange={() => setAccept(!accept)}
                            name="checkedA"
                        />
                    }
                    label={
                        <Typography fontSize={14} fontWeight={300}>
                            I accept{' '}
                            <Link
                                target="_blank"
                                href={termsUrl}
                                sx={{
                                    textDecoration: 'underline',
                                    textUnderlineOffset: '2px',
                                }}
                            >
                                Terms and conditions
                            </Link>
                        </Typography>
                    }
                />
                <Button disabled={!accept} type="submit" variant="outlined" sx={{width: '100%'}}>Sign Up</Button>
                <Typography
                    fontWeight={600}
                    fontSize={14}
                    textAlign="center"
                    marginY="35px"
                >
                    <Link href="/login">Back to login</Link>
                </Typography>
            </form>
        </HeaderFooterWrapper>
    );
}
