import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {useLocation} from 'react-router';
import {sendBody} from '../common/apiUtil';
import HeaderFooterWrapper from '../component/HeaderFooterWrapper';

export default function ResetPasswordPage() {
    const uuid = new URLSearchParams(useLocation().search).get('uuid');

    const [password, setPassword] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');
    const [err, serErr] = useState<string | undefined>(undefined);

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        sendBody(
            {
                password: password,
                password2: password2,
                uuid: uuid
            },
            '/api/user/reset-password',
            'POST',
            () => window.location.href = '/',
            serErr
        );
    };

    return (
        <HeaderFooterWrapper>
            <form onSubmit={submit} style={{ maxWidth: '360px', width: '100%' }}>
                {err ? <Alert severity="error">{err}</Alert> : null}
                <TextField sx={{marginTop: 2}} variant="outlined" fullWidth label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <TextField sx={{marginTop: 2}} variant="outlined" fullWidth label="Confirm password" type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
                <Button type="submit" sx={{marginTop: 2, width: '100%'}} variant="outlined">Reset</Button>
            </form>
        </HeaderFooterWrapper>
    );
}
