import React, {useState} from 'react';
import {Box} from '@mui/material';

export default function ImageWithFallback({image, width, onClick}: {image: string, width?: number, onClick?: () => void}) {
    const [url, setUrl] = useState(image);
    return (
        <Box
            onClick={onClick}
            sx={{width: width || 180, height: 'auto'}}
            component="img"
            src={url}
            onError={() => setUrl(window.location.origin + '/images/venue_placeholder.png')}
        />
    )
}
