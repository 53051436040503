import React from 'react';
import apiService from '../common/apiService';
import Promotion from '../types/Promotion';
import ApiError from '../component/ApiError';
import {useParams} from 'react-router-dom';
import {Box, Chip, Typography} from '@mui/material';
import Link from '@mui/material/Link';
import useWindowDimensions from '../common/useWindowDimensions';
import PromotionCard from '../component/PromotionCard';
import Button from '@mui/material/Button';
import CenteredDiv from '../component/CenteredDiv';
import ImageWithFallback from '../component/ImageWithFallback';

interface MobileViewProps {
    promotion: Promotion;
    width: number;
}

function MobileView({promotion, width}: MobileViewProps) {
    return (
        <Box sx={{p: 1}}>
            <ImageWithFallback
                width={width - 16}
                image={window.location.origin + '/images/' + promotion.picture} />
            <Typography variant="h6">{promotion.name}</Typography>
            <Typography variant="body2">{promotion.description}</Typography>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                <Link href={'/venue/' + promotion.venue.id}>{promotion.venue.name}</Link>
            </Typography>
            <Chip size="small" label={promotion.category} />
        </Box>
    );
}

export default function PromoPage() {
    const { width } = useWindowDimensions();
    const params = useParams();
    if (!params.id) {
        return <div>not found</div>;
    }

    const response = apiService<Promotion>('promo/' + params.id);

    if (response.error) {
        return <ApiError error={response.error}/>;
    }

    if (response.isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {width < 700
                ? <MobileView width={width} promotion={response.result} />
                : <PromotionCard width={420} promotion={response.result} />}
            <CenteredDiv>
                <Button
                    sx={{minWidth: 160}}
                    variant="outlined"
                    onClick={() => window.alert('sup')}
                    size="large"
                >CLAIM</Button>
            </CenteredDiv>
        </div>
    );
}
