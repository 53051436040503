import Grid from '@mui/material/Grid2';
import React from 'react';

interface Props {
    children: any;
}

export default function CenteredDiv({children} : Props) {
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid>
                {children}
            </Grid>
        </Grid>
    );
}
