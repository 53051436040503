import React from 'react';
import Error from '../types/Error';

interface Props {
    error : Error
}

export default function ApiError(props : Props) {
    const { error } = props
    return (
        <div>
            <h1>API error</h1>
            {error.status}  - {error.statusText}
        </div>
    );
}
