import React, { ReactNode } from 'react';

import { Box, Stack, Typography } from '@mui/material';

type HeaderFooterWrapperProps = {
    children: ReactNode;
};

const HeaderFooterWrapper = ({ children }: HeaderFooterWrapperProps) => {
    return (
        <Stack justifyContent="space-between" height="100vh">
            <Box
                padding={4}
                width={193}
                component="img"
                src="/logo640.png"
                alt="Dime"
            />
            <Stack alignItems="center" paddingX={2}>
                {children}
            </Stack>

            <Typography fontSize={14} padding={4}>
                © Dime {new Date().getFullYear()}
            </Typography>
        </Stack>
    );
};

export default HeaderFooterWrapper;
