import {Box, Chip, Typography} from '@mui/material';
import React from 'react';
import Venue from '../types/Venue';

interface VenueCardProps {
    venue: Venue;
}

export default function VenueCard({venue}: VenueCardProps) {
    return (
        <Box sx={{display: 'flex', p: 1}}>
            <Box
                sx={{height: 'auto', width: 420}}
                component="img" src="/images/venue_placeholder.png" />
            <Box sx={{flexGrow: 1, ml: 1}}>
                <Typography variant="h6">{venue.name}</Typography>
                <Typography variant="body2">{venue.description}</Typography>
                <Typography variant="body2">{venue.about}</Typography>
                <Typography variant="body2">{venue.address + ', ' + venue.city + ', ' + venue.country}</Typography>
                <Chip size="small" label={venue.category} />
            </Box>
        </Box>
    )
}
